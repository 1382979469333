/*! GOV.GR Bootstrap 4 Theme
 *
 *  Built on top of Bootstrap 4 (https://getbootstrap.com)
 *  Built by thodoris
 */
 @import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700');
 @import url('https://fonts.googleapis.com/icon?family=Material+Icons');

@import 'custom-variables';
@import '../node_modules/bootstrap/scss/bootstrap';
@import 'custom-styles';

